import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import initI18next, { t } from "@e360/i18n-util";
import htmlMfeLayout from "./microfrontend-layout.html";

initI18next(() => {

  const layoutData = {
    props: {
      navbarConfig: [
        {
          label: t('navbar.application.home.title', "Home"),
          icon: "fas fa-house",
          path: "/portal/org/:orgId",
          priority: 1
        },
        {
          label: t('navbar.application.dashboard.title', "Dashboard"),
          icon: "fas fa-square-poll-vertical",
          path: "/portal/org/:orgId/dashboard",
          priority: 20
        },
        {
          label: t('navbar.application.projects.title', "Projects"),
          icon: "fas fa-arrow-progress",
          path: "/portal/org/:orgId/project/list",
          priority: 30
        },
        {
          label: t('navbar.application.reports.title', "Reports"),
          icon: "fa-solid fa-file-chart-column",
          path: "/portal/org/:orgId/reports",
          permissions: {
              showFor: "report:read"
          },
          priority: 40
        },
        {
          label: t('navbar.application.contracts.title', "Contracts"),
          icon: "fa-solid fa-file-contract",
          path: "/portal/org/:orgId/contracts",
          permissions: {
              showFor: "contract:read"
          },
          priority: 50
        },
        {
          label: t('navbar.application.time-tracking.title', "Time Tracking"),
          icon: "fa-solid fa-clock",
          path: "/portal/org/:orgId/time",
          permissions: {
              showFor: "time-card:read"
          },
          priority: 60
        },
        {
          label: t('navbar.application.tasks.title', "Tasks"),
          icon: "fa-solid fa-bars-progress",
          path: "/portal/org/:orgId/tasks",
          permissions: {
              showFor: "tasks:read"
          },
          priority: 70
        },
        {
          label: t('navbar.application.calendar.title', "Calendar"),
          icon: "fa fa-calendar",
          path: "/portal/org/:orgId/calendar",
          permissions: {
              showFor: "calendar:read"
          },
          priority: 80
        },
      ]
    }
  }

  const routes = constructRoutes(htmlMfeLayout, layoutData);
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    }
  });
  
  const layoutEngine = constructLayoutEngine({ routes, applications });
  
  applications.forEach(registerApplication);
  layoutEngine.activate();
  
  start();
})
